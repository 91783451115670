<template>
    <h1 class="control-panel-title">Dashboard</h1>
    <div class="control-panel-content container-fluid boat-wrapper">
        <div :class="sinkState" class="boat"></div>
        <div class="blue"></div>
        <div class="not-boat">

            <div v-if="unreadEmails > 0" class="action-required">
                <h1 style="width: 99%; text-align: center;">Actie vereist</h1>
                <h3>
                    U heeft {{ unreadEmails }} ongelezen berichten in uw inbox.
                    <router-link v-if="unreadEmails > 0" :to="{ name: 'ControlPanelInbox' }">Bekijk inbox
                        &raquo;</router-link>
                </h3>
                <!-- <h3>
                    U heeft {{ unreadVoicemails }} nieuwe voicemailberichten.
                    <router-link v-if="unreadVoicemails > 0" :to="{ name: 'ControlPanelPhone' }">Beluister voicemails
                        &raquo;</router-link>
                </h3> -->
            </div>

            <div class="action-required">
                <h1 class="subheader">Deze ronde eindigt in <b class="large-timer">{{ endTime }}</b></h1>
            </div>

            <div v-if="areMetersVisible" class="action-required">
                <DialView :value="valueMeter1" :min="32" :max="68" class="dial" title="Stemming<br />Toezichthouders" />

                <DialView :value="valueMeter2" :min="38" :max="62" class="dial" title="Stemming<br />Deelnemers" />

                <DialView :value="valueMeter3" :min="36" :max="58" class="dial" title="Reputatie<br />Media" />

                <br />

                <DialView :value="valueMeter4" :min="44" :max="55" class="dial" title="Stemming<br />Werkgevers" />

                <DialView :value="valueMeter5" :min="40" :max="56" class="dial" title="Kwaliteit<br />Dienstverlening" />
            </div>

            <div style="min-width: 25em;" class="action-required">

                <ul>
                    <li class="icon-header"><!-- <span class="emoji">{{ websiteEmoji }}</span>-->
                        <span class="emoji"><font-awesome-icon :icon="websiteEmoji" /></span>
                        <span>Er zijn <b>{{ bezoekers
                        }}</b>
                            bezoeker(s) op de website.</span>
                    </li>
                    <li class="icon-header"><span class="emoji"><font-awesome-icon :icon="kccEmoji" /></span> <span>Drukte
                            klantcontactcentrum:
                            <b>{{ kcc }}%</b></span></li>
                    <li class="icon-header" style="padding-left: 2.9em; display: none;" v-if="kcc > 60">
                        [&nbsp;<a v-if="kccEnabled" class="simple-button" @click.prevent="disableKcc()">Uitschakelen</a><a
                            class="simple-button" v-if="!kccEnabled" @click.prevent="enableKcc()">Inschakelen</a>&nbsp;]
                    </li>
                    <li class="icon-header"><span class="emoji" @click="debugTapped++"><!-- 🔐 -->
                            <font-awesome-icon icon="fa-solid fa-user-lock" />
                        </span> <span>Administratiecode
                            <b>{{
                                adminCode
                            }}</b></span>
                        <DebugButtons v-if="debugTapped > 5" />
                        <span v-if="debugTapped > 5">Stap: {{ dilemmaId }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import gametree from '@/repository/gametree';
import DialView from '@/components/controlpanel/DialView.vue';
import DebugButtons from '@/components/controlpanel/DebugButtons.vue';

export default {
    name: "DashboardView",
    data() {
        return {
            kccEnabled: true, //this.$store.state.session.kccEnabled
            debugTapped: 0,
            endTime: "--:--",
            interval: null,
            valueMeter1: 50,
            valueMeter2: 50,
            valueMeter3: 50,
            valueMeter4: 50,
            valueMeter5: 50
        }
    },
    mounted() {
        this.interval = window.setInterval(this.tick);
    },
    unmounted() {
        clearInterval(this.interval, 200);
    },
    computed: {
        adminCode() {
            return this.session.adminCode;
        },
        dilemmaId() {
            if (!this.session.progress) {
                return ' ';
            }
            return gametree.getDilemmaId(this.session.variant, this.session.progress);
        },
        bezoekers() {
            if (!this.session.progress) {
                return 0;
            }
            const progress = this.session.progress;
            return Math.floor(5 + progress * 11.4 + Math.random() * 3);
        },
        kcc() {
            if (!this.session.progress) {
                return '--';
            }
            if (!this.kccEnabled) {
                return '--';
            }
            const progress = this.session.progress;
            if (progress < 6) {
                return 6 + Math.round(Math.random() * 2);
            }
            return 50 + Math.floor(progress * 50 / gametree.progressStates(this.session.variant).length);
        },
        websiteEmoji() {
            if (!this.$store.state.sessionToken || !this.session.progress) {
                return ' ';
            }
            const progress = this.session.progress;
            // const emojis = ['🙂', '📈', '👍', '🔥'];
            const emojis = [
                ['fa-regular', 'face-smile'],
                ['fa-solid', 'chart-line'],
                ['fa-regular', 'thumbs-up'],
                ['fa-solid', 'fire']
            ];
            const index = Math.floor(progress * emojis.length / gametree.progressStates(this.session.variant).length);

            if (index < 0) return emojis[0];
            if (index >= emojis.length) return emojis[emojis.length - 1];

            return emojis[index];
        },
        kccEmoji() {
            if (!this.session.progress) {
                return ['fa-regular', 'hourglass'];
            }
            if (!this.kccEnabled) {
                return ['fa-regular', 'ban'];
            }
            const progress = this.session.progress;
            // const emojis = ['😄', '😃', '😀', '🙂', '😐', '😅', '😟', '😓', '😥', '😰', '😠', '😡', '🤬'];
            const emojis = [
                ['fa-regular', 'face-laugh-beam'],
                ['fa-regular', 'face-laugh'],
                ['fa-regular', 'face-wide-grin'],
                ['fa-regular', 'face-smile-beam'],
                ['fa-regular', 'face-smile'],
                ['fa-regular', 'face-grin-beam-sweat'],
                ['fa-regular', 'face-frown-open'],
                ['fa-regular', 'face-frown'],
                ['fa-regular', 'face-sad-tear'],
                ['fa-regular', 'face-tired'],
                ['fa-regular', 'face-angry']
            ];

            const index = Math.floor(progress * emojis.length / gametree.progressStates(this.session.variant).length);

            // Ensure the index is within the array bounds
            if (index < 0) return emojis[0];
            if (index >= emojis.length) return emojis[emojis.length - 1];

            return emojis[index];
        },
        sinkState() {
            if (!this.session.progress) {
                return 'boat-1';
            }
            const progress = this.session.progress;
            if (progress >= 12) {
                return 'boat-6';
            } else if (progress >= 10) {
                return 'boat-4';
            } else if (progress >= 8) {
                return 'boat-3';
            } else if (progress >= 6) {
                return 'boat-2';
            } else {
                return 'boat-1';
            }
        },
        unreadEmails() {
            const now = Date.now();
            const state = this.$store.state;
            if (state) {
                if (!state.session) {
                    console.log('state.session is empty');
                    return 0;
                }
                if (!state.session.steps) {
                    console.log('state.session.steps is empty');
                    return 0;
                }
                return state.session.steps.filter(msg => msg.isRead === false && now > new Date(msg.visible).getTime() && !msg.isVoicemail).length
            }
            return 0;
        },
        // unreadVoicemails() {
        //     const now = Date.now();
        //     const state = this.$store.state;
        //     if (state) {
        //         if (!state.session) {
        //             console.log('state.session is empty');
        //             return 0;
        //         }
        //         if (!state.session.steps) {
        //             console.log('state.session.steps is empty');
        //             return 0;
        //         }
        //         return state.session.steps.filter(msg => msg.isRead === false && now > new Date(msg.visible).getTime() && msg.isVoicemail).length
        //     }
        //     return 0;
        // },
        areMetersVisible() {
            return true; // gametree.areMetersVisible(this.session.variant, this.session.progress);
        },
        session() {
            if (!this.$store.state.sessionToken) {
                return -1;
            }
            return this.$store.state.session;
        }
    },
    methods: {
        enableKcc() {
            this.kccEnabled = true;
        },
        disableKcc() {
            this.kccEnabled = false;
        },
        tick() {
            const time = this.endTimeOfRound();
            if (time != null) {
                const now = new Date().getTime();
                const difference = time - now;

                if (difference <= 0) {
                    return "00:00";
                }

                const minutes = Math.floor(difference / 60000);
                const seconds = Math.floor((difference % 60000) / 1000);

                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');

                this.endTime = `${formattedMinutes}:${formattedSeconds}`;
            }

            if (this.session) {
                this.valueMeter1 = this.session.valueMeter1;
                this.valueMeter2 = this.session.valueMeter2;
                this.valueMeter3 = this.session.valueMeter3;
                this.valueMeter4 = this.session.valueMeter4;
                this.valueMeter5 = this.session.valueMeter5;
            }
        },
        endTimeOfRound() {
            if (!this.$store.state.sessionToken) {
                return null;
            }
            try {
                const states = this.session.steps;
                const dilemma = states[this.session.progress];
                if (dilemma) {
                    if (dilemma.isPause) {
                        return null;
                    }

                    if (dilemma.isRound2) {
                        if (this.session.startTimeRound2) {
                            const lastState = states[states.length - 1];
                            const endTime = new Date(lastState.startTime).getTime() + (lastState.duration || 0);
                            // const date = new Date(endTime);
                            // const hours = String(date.getHours()).padStart(2, '0');
                            // const minutes = String(date.getMinutes()).padStart(2, '0');
                            // return `${hours}:${minutes}`;
                            return endTime;
                        } else {
                            return null;
                        }
                    } else {
                        const pauseState = states.find(state => state.isPause);
                        if (pauseState.startTime) {
                            const endTime = new Date(pauseState.startTime);
                            // const hours = String(endTime.getHours()).padStart(2, '0');
                            // const minutes = String(endTime.getMinutes()).padStart(2, '0');
                            // return `${hours}:${minutes}`;
                            return endTime.getTime();
                        } else {
                            return null;
                        }
                    }
                } else {
                    return null;
                }
            } catch (e) {
                console.log(e);
                return null;
            }
        }
    },
    components: {
        DialView,
        DebugButtons
    }
}
</script>

<style scoped>
.boat-wrapper {
    position: relative;
}

.boat {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0.875rem;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    /* filter: blur(3px); */
    z-index: 1;
    /* -webkit-mask-image: linear-gradient(to bottom, transparent, black 50%, black), */
    /* linear-gradient(to right, transparent, black 50%, black); */
    mask-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.25));
    /* mask-image: radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.5), transparent); */

    filter: grayscale(100%);
}

.sidebyside {
    flex: 1;
}

.boat-6 {
    background-image: url('@/assets/boat-6.jpg');
}

.boat-5 {
    background-image: url('@/assets/boat-5.jpg');
}

.boat-4 {
    background-image: url('@/assets/boat-4.jpg');
}

.boat-3 {
    background-image: url('@/assets/boat-3.jpg');
}

.boat-2 {
    background-image: url('@/assets/boat-2.jpg');
}

.boat-1 {
    background-image: url('@/assets/boat-1.jpg');
}


.blue {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0.875rem;
    background-color: var(--color-blue);
    mix-blend-mode: soft-light;
    z-index: 2;
}

.not-boat {
    position: relative;
    z-index: 3;
    /* min-height: 30em; */
    /* display: flex; */
    /* flex-wrap: wrap; */
}

.not-boat ul {
    padding-left: 0;
}

.icon-header {
    list-style-type: none;
    vertical-align: middle;
}

.icon-header span:not(.emoji) {
    display: inline-block;
    line-height: 48pt;
    font-size: 22pt;
    vertical-align: middle;
}

.icon-header .emoji {
    font-size: 23pt;
    vertical-align: middle;
    margin-right: 32px;
    vertical-align: middle;
    width: 2rem;
    display: inline-block;
}

.sidebyside h1 {
    margin-top: 34px;
}

.action-required {
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
}

.action-required h3 {
    margin: 23px 0px;
}

.action-required h1 {
    text-align: left !important;
}

.simple-button {
    cursor: pointer;
}

.dial {
    display: inline-block;
}

h1.subheader {
    /* margin-top: 2rem;
    margin-bottom: 1.5rem; */
    font-size: 2rem;
    text-align: center;
}

.large-timer {
    font-size: 4rem;
}
</style>
