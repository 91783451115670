<template>
    <OverlayBrowser :isOpen="openOverlay" :iframeURL="iframeURL" @close="openOverlay = false" />
    <div class="col-12">
        <div v-if="email.type != 'y'">
            <span class="email-subject">{{ email.subject }}</span>
            <img :src="avatarSrc" class="avatar" width="72" />
            <div class="email-from">
                {{ email.sender }} <span class="email-address">{{ email.from }}</span>
                <br />
                <span class="email-to">Aan: {{ email.receiver }}</span>
            </div>
        </div>

        <div class="email-body" v-if="email.id == 'email1'">
            <p>
                Om te vieren dat ik weer terug ben van ouderschapsverlof, staat er taart in de keuken! Wees er snel bij!
                Keuze tussen chocola en zelfgemaakte appeltaart. @Theo: die laatste is helemaal vegan!
                <br /><br />Groetjes,
                <br />Jacqueline
            </p>
            <img src="@/assets/taart.png" />
        </div>
        <div class="email-body" v-if="email.id == 'email3'">
            <p>
                Hoi allemaal,
                <br /><br />Kan degene die gisteren taart in de keuken heeft gezet dat ook weer netjes opruimen alsjeblieft?
            </p>
        </div>
        <div class="email-body" v-if="email.id == 'stap-2'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-2-attachment-1.pdf')">Attachment 1 -
                    emails.pdf</a>
                <!-- <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-2-attachment-2.pdf')">Attachment 2 -
                    screenshots.pdf</a> -->
            </p>
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                In het licht van onze voortdurende toewijding om de integriteit en veiligheid van onze dienstverlening te
                waarborgen, wens ik een recente waarneming met u te delen.
            </p>
            <p>
                We hebben enkele signalen opgevangen die duiden op externe activiteiten waarbij de naam van SereneFuture
                wordt misbruikt. Concreet hebben wij meldingen binnen gekregen, waarbij de naam van SereneFuture in
                phishingmails genoemd is. Zoals u weet, zijn grote financiële instanties zoals uw organisatie regelmatig het
                onderwerp van belangstelling in de digitale wereld, en hoewel dit een aspect van de moderne zakenwereld is,
                blijven we waakzaam.
            </p>
            <p>
                Voor een duidelijk overzicht van de meldingen heb ik enkele berichten aan deze e-mail toegevoegd. Uiteraard
                zorgen wij ervoor dat de deelnemers binnen ons mandaat goed geïnformeerd en ondersteund worden. Deze
                berichten dienen dan ook alleen ter informatie.
            </p>
            <p>
                Wij zijn uitermate voorbereid op eventuele calamiteiten en hebben hiervoor een draaiboek liggen dat we
                regelmatig testen en verbeteren. Zo hebben we ook een standaard instructie voor ons Klant Contact Centrum
                (KCC) waarin omschreven is hoe zij om dienen te gaan met vragen vanuit deelnemers. Wij bemerken op dit
                moment een verhoogd aantal vragen vanuit uw deelnemers op dit onderwerp, en handelen deze conform de heldere
                en uniforme instructie af.
            </p>
            <p>
                Ik wil graag benadrukken dat, tot op heden, alle interne controles aangeven dat onze systemen veilig en
                intact zijn. Onze organisatie heeft een robuust beleid en voert regelmatig audits uit om de beveiliging en
                integriteit van onze systemen te waarborgen.
            </p>
            <p>
                Indien u verdere vragen heeft of specifieke instructies wilt verstrekken, verneem ik die graag zo spoedig
                mogelijk. Ik zal er vervolgens voor zorgen dat deze met de grootste spoed behandeld worden. Indien u besluit
                zelfstandig actie te ondernemen buiten onze diensten om, stel ik het op prijs als u de door u gekozen aanpak
                met mij deelt.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Wacht af of er nog andere meldingen komen voordat er actie ondernomen wordt.</li>
                <li>Vraag de PUO te achterhalen wat er in de phishingmails staat en waar ze vandaan komen.</li>
                <li>Zet een waarschuwing op de website van SereneFuture voor mogelijke phishingaanvallen.</li>
                <li>Stuur een mail naar deelnemers dat er phishers bezig zijn en dat ze het beste op kunnen passen wanneer
                    ze een mail krijgen.</li>
                <li>Maak een melding bij de toezichthouder.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Wacht af" @click="emailStap2(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Vraag PUO" @click="emailStap2(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Waarschuwing" @click="emailStap2(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Deelnemers" @click="emailStap2(3)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="5. Melden" @click="emailStap2(4)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-3'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                In het licht van onze voortdurende inspanningen om de belangen van u en uw deelnemers te waarborgen en onze
                verantwoordelijkheden met de grootst mogelijke zorgvuldigheid te benaderen, voel ik de noodzaak u te
                informeren over recente signalen die suggereren dat bepaalde deelnemersgegevens zich mogelijk in een
                onbedoelde context op het internet bevinden. Ik wil u verzekeren dat we hiervan op de hoogte zijn en dat
                deze kwestie intern met de allerhoogste prioriteit wordt behandeld. Zoals u zult begrijpen, is de
                integriteit en veiligheid van de gegevens van het pensioenfonds en haar deelnemers voor ons van het
                allergrootste belang.
            </p>
            <p>
                In het steeds veranderende digitale landschap van de laatste jaren, zijn er tal van instanties geweest die
                ongewild te maken hebben gehad met datalekken. Een bijzonder punt van aandacht hierbij is dat het niet
                altijd gaat om direct bij een partij gelekte gegevens. Er zijn gevallen bekend waarbij elders gelekte
                informatie wordt gebruikt en gecombineerd, en vervolgens op een zodanige wijze wordt gepresenteerd dat het
                lijkt alsof de gegevens rechtstreeks van bedrijven zoals SereneFuture afkomstig zijn, met als doel u onder
                druk te zetten. Hierbij is het van belang te onderkennen dat SereneFuture, vanwege haar positie in de
                financiële sector, inderdaad aantrekkelijk kan zijn voor dergelijke tactieken.
            </p>
            <p>
                In de berichten online komt de naam MassMailing naast het pensioenfonds regelmatig naar voren. Ik kan
                bevestigen dat wij als pensioenuitvoerder MassMailing inzetten voor het distribueren van informatieve
                e-mails en vragenlijsten aan uw deelnemers.
                Ik begrijp de vervelende situatie waar u in zit en wij kijken ook of MassMailing alle verplichtingen
                richting ons specifiek en de AVG in het algemeen goed is nagekomen en blijft nakomen. Officieel heb ik nog
                geen bericht ontvangen over een storing of fout bij MassMailing, maar gezien alle signalen zou ik daar als
                eerste mijn aandacht op vestigen.
            </p>
            <p>
                Ik zal ervoor zorgen dat u regelmatig op de hoogte wordt gehouden van onze bevindingen en de stappen die we
                nemen om deze situatie aan te pakken. Wij zijn vastbesloten om de veiligheid van de gegevens van
                SereneFuture en haar deelnemers te waarborgen en het vertrouwen dat u in ons stelt te blijven verdienen.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Focus op intern onderzoek door de IT-afdeling van de PUO naar een datalek.</li>
                <li>Vindt uit welke mailadressen exact bekend zijn bij MASS Mailing.</li>
                <li>Neem contact op met MASS Mailing en betrek ze bij verdere communicatie.</li>
                <li>Vertel MASS Mailing dat er een datalek bij ze heeft plaatsgevonden en dat ze dit moeten oplossen.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Onderzoek" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Mailadressen" @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. MASS Mailing" @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Datalek" @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-4'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                Wij nemen contact met u op om u op de hoogte te stellen van een uitdaging waarmee wij momenteel worden
                geconfronteerd. Een aantal van onze cruciale systemen is op dit moment helaas niet beschikbaar. Het meest
                zichtbare
                systeem is het inlogsysteem, waardoor deelnemers geen informatie kunnen inzien of wijzigingen kunnen
                doorgeven.
                Initieel hadden we de verwachting dat deze tijdelijke onbeschikbaarheid snel verholpen zou zijn. Echter, uit
                de
                eerste analyses van onze engineers blijkt dat er incompatibiliteiten zijn ontstaan tussen de versies van de
                verschillende systemen en het herstel iets langer gaat duren.
            </p>
            <p>
                We zijn momenteel druk bezig met een grondig onderzoek om de hoofdoorzaak van dit probleem te achterhalen.
                Persoonlijk verwacht ik dat de meest waarschijnlijke oorzaak die nu naar voren komt, een recent uitgevoerde
                update van een van onze systemen zal zijn. Deze update moet dan een onvoorziene wisselwerking hebben met
                andere systemen, wat resulteert in de huidige incompatibiliteit.
            </p>
            <p>
                Updates zijn een essentieel onderdeel van ons beheerprotocol; ze zorgen ervoor dat we actueel blijven en dat
                we de nieuwste dreigingen het hoofd kunnen bieden en risico's effectief kunnen pareren. Echter kunnen ze
                soms onbedoelde bijeffecten hebben.
            </p>
            <p>
                We begrijpen het cruciale belang van deze systemen voor de dagelijkse operatie en voor het vertrouwen van
                onze deelnemers. We werken daarom onophoudelijk aan een oplossing en zullen u direct informeren zodra we
                meer inzicht hebben in de situatie en de verwachte hersteltijd.
            </p>
            <p>
                Wij vragen om uw begrip en geduld terwijl we deze technische hindernissen aanpakken. Wij benadrukken dat het
                onze topprioriteit is om de systemen zo snel mogelijk weer operationeel te krijgen.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <p>
                <span class="span-important">Actie ondernemen:</span>
            <ol class="action-options">
                <li>Wacht verder onderzoek van de IT-afdeling van de PUO af.</li>
                <li>Schakel externe experts in.</li>
                <li>Schakel portaal uit en communiceer naar de deelnemers dat portalen er tijdelijk uitliggen.</li>
                <li>Vraag de accountmanager van de PUO voor meer informatie over welke deelnemers getroffen zijn.</li>
            </ol>
            </p>
            <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                :remainingSeconds="email.remaining" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Wacht af" @click="emailStap4(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Experts inschakelen"
                @click="emailStap4(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Portaal uitzetten"
                @click="emailStap4(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Vraag informatie" @click="emailStap4(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-5'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-5-attachment-1.pdf')">Attachment 1 -
                    scan.pdf</a>
            </p>
            <p>
                Hoi,
            </p>
            <p>
                Kreeg net deze met de post, leek me erg belangrijk!<br />
                Zie attachment.
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Neem nog geen contact op met de toezichthouder.</li>
                <li>Vertel de toezichthouder dat de mailadressen waarschijnlijk verkregen zijn via MASS Mailing.</li>
                <li>Vertel de toezichthouder dat er momenteel een onderzoek gaande is, maar dat je nu nog niks kunt zeggen.
                </li>
                <li>Geef aan dat het probleem bij de PUO ligt en deze het probleem moet oplossen.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Wacht af" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. MASS Mailing" @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Onderzoek gaande"
                @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. PUO" @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-6'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment" @click.prevent="openOverlayWithUrl('/attachments/email-6.pdf')">Attachment 1 -
                    official-statement.pdf</a>
            </p>

            <img src="@/assets/email-6.png">
        </div>

        <div class="email-body" v-if="email.id == 'stap-7'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                Met betrekking tot onze voortdurende inspanningen rond de recentelijk geïdentificeerde anomalie en de
                meldingen over gegevens die online circuleren, wens ik u een verdere update te geven over de stand van
                zaken.
            </p>
            <p>
                Na een diepgaand onderzoek kunnen we op dit moment niet uitsluiten dat er enige relatie bestaat tussen de
                gedetecteerde anomalie in ons systeem en de gevonden gegevens op het internet. Hierbij is het essentieel te
                vermelden dat het lijkt te gaan om een beperkt geïsoleerd systeem en niet de hoofdinfrastructuur van onze
                organisatie.
            </p>
            <p>
                Het is eveneens van belang om te benadrukken dat, ondanks de focus van de media op deze kwestie, de
                feitelijke omvang van de gegevens in kwestie relatief beperkt lijkt te zijn. Wij zijn van mening dat deze
                situatie mogelijk opzettelijk wordt uitvergroot in de media om ons maximaal onder druk te zetten.
            </p>
            <p>
                Mede dankzij onze robuuste beveiligingsmaatregelen en procedures hebben we redenen om aan te nemen dat het
                slechts een kleine dataset betreft binnen dit geïsoleerde systeem. Echter, elke onregelmatigheid in de
                bescherming van deelnemersgegevens nemen wij uiterst serieus. Hoewel deze situatie mogelijk wordt opgeblazen
                in de publieke sfeer, willen wij u verzekeren dat dit niet betekent dat de veiligheid van de gegevens op
                grote schaal in het geding is gekomen.
            </p>
            <p>
                We blijven vastberaden in onze inspanningen om de integriteit van onze systemen te waarborgen en zullen
                alles in het werk stellen om de vertrouwensband met SereneFuture en haar deelnemers en stakeholders te
                behouden en waar nodig te herstellen.
            </p>
            <p>
                Ik dank u voor uw begrip en vertrouwen in deze complexe kwestie en zal u blijven informeren over verdere
                ontwikkelingen.
            </p>
            <p>
                Met vriendelijke groet,
            </p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
        </div>

        <div class="email-body" v-if="email.id == 'stap-8'">
            <p>
                Hallo bestuur,
            </p>
            <p>
                Tijdens een kort moment van afwezigheid heeft iemand een voicemail ingesproken voor het bestuur. Gezien de
                aard en de toon van het bericht, leek het me dringend en noodzakelijk om dit direct aan jullie door te
                geven.
            </p>
            <p>
                Ik heb het bericht naar de gezamenlijke mailbox van het bestuur doorgestuurd zodat jullie het kunnen
                beluisteren. Ik vertrouw erop dat jullie het verder oppakken.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Tessa Willemsen
            </p>
            <p>
                <!-- <ButtonView class="btn-email-todo" text="Ga naar voicemailbox" @click="gotoVoicemails()" /> -->
            <table class="table voicemails-list">
                <thead>
                    <th>Afzender</th>
                    <th>Beluisteren</th>
                </thead>
                <tbody>
                    <tr class="voicemail-unread">
                        <td class="voicemail-name">Fred de Haan</td>
                        <td class="voicemail-audio">
                            <audio controls src="/attachments/voicemail-stap-7.mp3" @play="markAsRead(voicemail)"></audio>
                        </td>
                    </tr>
                </tbody>
            </table>
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-8b'">
            <!-- <p>
                <audio controls :src="email.audioFile"></audio>
            </p> -->
            <video ref="videoPlayer" controls autoplay>
                <source :src="email.videoFile" type="video/mp4">
            </video>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Reageer niet.</li>
                <li>Maak een statement waarin je vertelt dat de speculaties onjuist zijn, maar niet in detail gaat.</li>
                <li>Maak een statement waarin je deelt wat je weet. Dit vereist wel dat je specifiek ingaat op de technische
                    details en securitymaatregelen die getroffen zijn.</li>
                <li>Zet een medewerker van het fonds in om bij het radioprogramma op bezoek te gaan voor een gesprek.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Reageer niet" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Generieke reactie"
                @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Gedetailleerde reactie"
                @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Bezoek" @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-8c'">
            <p>
                Hoi,
            </p>
            <p>
                De resultaten van ons stakeholdertevredenheidsonderzoek zijn binnengekomen. Als het goed is hebben jullie nu
                een knop in het dashboard om die te bekijken, en anders kan het via onderstaande link. Ik heb het
                zelf nog niet gezien, ik hoop dat het goed gaat daar!
            </p>
            <p>
                De pagina is overigens alleen tijdelijk beschikbaar.
            </p>
            <p>
                <ButtonView class="btn-email-todo" text="Bekijk de meters" @click="bekijkMeters()" />
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-9'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                Zoals u wellicht heeft vernomen via de media gaat het verhaal rond dat uw deelnemers verkeerde uitbetalingen
                ontvangen.
                Wij volgen deze berichtgeving zeer nauwgezet en monitoren actief de situatie binnen onze organisatie.
            </p>
            <p>
                Gezien de beschikbare informatie kunnen we de mogelijkheid niet uitsluiten dat sommige van uw deelnemers
                wellicht hebben gereageerd op phishing e-mails, wat hen naar valse websites heeft geleid door op
                kwaadaardige links te klikken. Als gevolg hiervan is het mogelijk dat er zonder toestemming toegang is
                verkregen tot het deelnemersportaal van SereneFuture, waarbij accountinformatie van deelnemers is gewijzigd.
            </p>
            <p>We hebben de gegevens van enkele meldingen geanalyseerd en ontdekten dat bij een aantal incidenten het
                bankrekeningnummer was gewijzigd in een nummer dat toebehoorde aan een bank buiten de Europese Unie,
                specifiek in Kazachstan. We zijn erin geslaagd om enkele transacties te stoppen of om te keren, maar niet in
                alle gevallen. Hoewel wij deze preventieve maatregelen zien als een extra laag van bescherming voor onze
                deelnemers, benadruk ik dat deelnemers ook een eigen verantwoordelijkheid dragen in het geval van phishing.
            </p>
            <p>Ik wil hierbij
                nadrukkelijk vermelden dat dit niet duidt op een inbreuk in de beveiligde systemen van SereneFuture zelf,
                maar veeleer een gevolg is van acties ondernomen door individuele deelnemers.
            </p>
            <p>
                Hoewel de oorsprong van dit probleem mogelijk bij de deelnemers zelf ligt, erkennen we volledig de ernst en
                de mogelijke gevolgen voor het pensioenfonds en het bredere vertrouwen in de sector. Als pensioenuitvoerder
                hebben we een verantwoordelijkheid naar al uw deelnemers en stakeholders.
            </p>
            <p>
                Ons Klant Contact Centrum is momenteel volop bezig met het behandelen van meldingen en het ondersteunen van
                betrokken deelnemers. We zetten alles op alles om de situatie te verhelderen, de gevolgen te beperken en,
                waar mogelijk, herstelwerkzaamheden te verrichten.
            </p>
            <p>
                Wij zullen u blijven informeren over de voortgang en ontwikkelingen in deze kwestie.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Wacht verder nieuws van de pensioenuitvoerder af.</li>
                <li>Vraag de pensioenuitvoerder om gegevens te controleren om te kijken waar het probleem vandaan komt.</li>
                <li>Zeg de pensioenuitvoerder om te uitbetalingen voor getroffen deelnemers stop te zetten.</li>
                <li>Bel de bank om alle betalingen stop te zetten.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Wacht af" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Gevens controleren"
                @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. PUO: stopzetten" @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Alle betalingen stopzetten"
                @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-10'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                In het kader van onze voortdurende inspanningen rondom de veiligheid van onze gegevens, informeer ik u
                verder over het eerder genoemde geïsoleerde systeem.
            </p>
            <p>
                Dit systeem, dat alleen nog in gebruik is bij SereneFuture, draait op een oudere versie van de
                beheerssoftware. Het stond langere tijd in de planning om deze verouderde software uit te faseren en de
                gegevens te migreren naar een nieuwere, veiligere omgeving. Echter, tot op heden hadden we hiervoor nog geen
                medewerking vanuit SereneFuture ontvangen.
            </p>
            <p>
                Deze oudere softwareversie maakt gebruik van een bepaald package. Op basis van onze eerste analyses is het
                niet uit te sluiten dat onbevoegden via een zogeheten zero-day exploit toegang hebben verkregen tot bepaalde
                delen van het systeem. Hoewel we redenen hebben om aan te nemen dat het mogelijk enkel gaat om algemene,
                niet-persoonsgebonden gegevens, is ons IT-team intensief bezig de exacte omvang en aard van de toegang te
                bepalen.
            </p>
            <p>
                Ik had gehoopt in de door u gestuurde beleidsdocumenten te vinden dat dit daadwerkelijk op de planning
                stond. Helaas kon ik niet inloggen in de door u gestuurde link. Ondanks rappel van mijn kant, en ondanks uw
                vakantie, heb ik helaas nog geen update ontvangen hierover. Mocht het incident inderdaad in de afgelopen
                dagen hebben plaatsgevonden, dan zou een spoedigere reactie en actie vanuit uw kant wellicht dit hebben
                kunnen voorkomen.
            </p>
            <p>
                Voor de volledigheid wil ik ook toevoegen dat het niet uit te sluiten is dat deze bevindingen volstrekt
                ongerelateerd zijn aan de berichten die online circuleren. Deze online berichten hebben ons aangespoord tot
                een diepgravend onderzoek, waarbij we dit geïsoleerde systeem toevalligerwijs tegenkwamen.
            </p>
            <p>
                Ik wil benadrukken dat we de situatie met de hoogste prioriteit aanpakken. De integriteit van de gegevens
                van onze deelnemers en stakeholders is van het grootste belang en we blijven ons inzetten voor transparante
                communicatie over de voortgang van ons onderzoek.
            </p>
            <p>
                We waarderen uw geduld en vertrouwen in deze kwestie en zullen u blijven informeren over verdere
                ontwikkelingen.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
        </div>

        <div class="email-body" v-if="email.id == 'stap-12'">
            <p>
                Geacht bestuur,
            </p>
            <p>
                Ik schrijf u namens Pensioen Pro, een onafhankelijk journalistiek medium dat zich richt op het brede
                spectrum van pensioengerelateerde zaken. Gezien de recente ophef in diverse media omtrent de kwesties bij uw
                fonds zijn wij voornemens een diepgaand artikel te publiceren over deze situatie.
            </p>
            <p>
                Wij willen u daarom benaderen met de vraag in hoeverre uw fonds bereid is om medewerking te verlenen aan dit
                artikel. Er zijn verschillende vormen van medewerking mogelijk:
            </p>
            <p>
            <ul>
                <li>Een exclusief interview waarin we dieper ingaan op de situatie en het beleid van het fonds. Hierbij
                    zullen de hoofdvragen vooraf worden aangereikt, en het gesprek zal worden opgenomen om via een
                    transcriptie later verwerkt te worden. Wij willen benadrukken dat eventuele aanpassingen op eerder
                    uitgesproken statements achteraf niet meegenomen zullen worden. Met opmerkingen, toelichtingen en
                    kennelijke versprekingen houden we als vanzelfsprekend rekening. Het doel van het gesprek is om tot een
                    uitgebalanceerd journalistiek achtergrondverhaal te komen, zonder sensatie-quotes.
                    Het biedt u een uitgelezen kans om uitgebreid op de situatie in te gaan en uw kant van het verhaal te
                    belichten.</li>
                <li>Een later tijdstip waarop we uw reactie of input kunnen ontvangen. Hiermee kunt u enige tijd nemen om
                    een doordachte bijdrage te leveren. Wij sturen u dan het artikel op, waarop u kunt reageren. Wij zullen
                    in dit geval meer baseren op onze eigen bronnen in de sector en bij de stakeholders.</li>
                <li>Een kort statement over de huidige situatie en de genomen stappen vanuit het fonds.</li>
            </ul>
            </p>
            <p>
                Voor de volledigheid en transparantie naar onze lezers toe zullen wij sowieso experts raadplegen en indien
                mogelijk getroffen deelnemers interviewen om hun perspectieven en meningen te verkrijgen.
            </p>
            <p>
                Uw medewerking kan bijdragen aan een evenwichtig en genuanceerd beeld van de situatie. Wij hopen dan ook dat
                u bereid bent om samen te werken en uw visie met onze lezers te delen.
            </p>
            <p>
                Als vanzelfsprekend staat het u ook vrij om (op voorhand) niet te reageren. In dat geval zullen we u, na het
                schrijven van het artikel, maar voor publicatie, in het kader van wederhoor het artikel op sturen en vragen
                om een reactie.
            </p>
            <p>
                We zien graag uw reactie tegemoet en zijn beschikbaar voor verdere vragen of verduidelijking.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Camilla de Vries
            </p>
            <p>
                Techredacteur Pensioen Pro
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Reageer niet op de PensioenPro.</li>
                <li>Vertel PensioenPro dat je geen statement wil geven.</li>
                <li>Vertel PensioenPro dat er intern onderzoek wordt gedaan en dat het probleem bij een andere organisatie
                    lijkt te liggen.</li>
                <li>Vertel PensioenPro dat er intern onderzoek wordt gedaan en dat het te vroeg is om conclusies te trekken.
                </li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Reageer niet" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Geen statement" @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Onderzoek wijst niet naar ons"
                @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Onderzoek nog in gang"
                @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-13'">
            <p>
                Hoi,
            </p>
            <p>
                De PUO is nog niet met nieuwe informatie gekomen.
                Kan ik iets aan ze doorgeven of hebben jullie andere vervolgstappen in gedachten?
                Laat maar weten!
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Vraag geen specifieke informatie uit.</li>
                <li>Vraag wel specifieke informatie uit. Vul de aanvraag in op papier en druk op de knop wanneer je de lijst
                    hebt ingeleverd.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Niet uitvragen" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Klik hier als je de lijst hebt ingeleverd."
                @click="answerEmail(1)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-14'">
            <img src="@/assets/stap-14-attachment-1.png" class="image-attachment" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-15'">
            <p>
                Geacht bestuur van het pensioenfonds,
            </p>
            <p>
                Naar aanleiding van uw verzoek om aanvullende informatie over de recente ontwikkelingen betreffende de
                mogelijke aanwezigheid van deelnemersgegevens buiten de geëigende systemen, wil ik u graag informeren over
                de huidige stand van zaken.
            </p>
            <p>
                Wij nemen de huidige situatie buitengewoon serieus en begrijpen uw behoefte aan duidelijkheid en grondige
                informatie. Direct na ontvangst van uw verzoek heb ik deze kwestie intern geëscaleerd, en wij zijn momenteel
                bezig met een grondig en nauwgezet onderzoek.
            </p>
            <p>
                Ik ben het volledig met u eens dat het van cruciaal belang is om feiten in de juiste context te plaatsen.
                Het is belangrijk dat we volledige en accurate informatie verstrekken, aangezien deelinformatie die later
                irrelevant blijkt te zijn, of die anders geïnterpreteerd moet worden, kan leiden tot meer onduidelijkheid
                dan duidelijkheid.
            </p>
            <p>
                We zijn momenteel bezig met het verzamelen en analyseren van alle relevante gegevens, en we werken nauw
                samen met onze cybersecurity experts om een helder en compleet beeld te krijgen van de situatie. Ons doel is
                om u zo snel mogelijk te voorzien van concrete en duidelijke informatie.
            </p>
            <p>
                Zodra we over concrete en relevante informatie beschikken, zal deze onmiddellijk aan u worden
                teruggekoppeld. Wij waarderen uw geduld en begrip in deze complexe situatie en verzekeren u dat het onze
                hoogste prioriteit heeft.
            </p>
            <p>
                Met vriendelijke groet,
            </p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Geen onderzoek doen.</li>
                <li>Technisch rapport / forensisch onderzoek door een extern forensisch expert.</li>
                <li>Huidige impact op bedrijfsprocessen in kaart brengen.</li>
                <li>Wacht uitkomsten van het interne onderzoek af.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Geen onderzoek" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Technisch rapport"
                @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Huidige impact" @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Wacht af" @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-16-OLD'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                Ik wend mij tot u in verband met recente mediaberichten waarin wordt gesuggereerd dat betalingen aan uw
                deelnemers mogelijk zijn aangepast. Wij volgen deze berichtgeving zeer nauwgezet en monitoren actief de
                situatie binnen onze organisatie.
            </p>
            <p>
                Een verband tussen deze berichtgeving en de eerder door ons gecommuniceerde phishingactiviteiten is ons niet
                bekend. Op basis van de huidige informatie sluiten we niet uit dat enkele van uw deelnemers mogelijk op
                dergelijke phishing-mails hebben gereageerd, door op links te klikken die hen naar frauduleuze websites
                leidden. Indien dit het geval is, kan dit hebben geresulteerd in ongeautoriseerde toegang tot het
                deelnemersportaal van SereneFuture en aanpassingen aan accountgegevens van deelnemers. Ik wil hierbij
                nadrukkelijk vermelden dat dit niet duidt op een inbreuk in de beveiligde systemen van SereneFuture zelf,
                maar veeleer een gevolg is van acties ondernomen door individuele deelnemers.
            </p>
            <p>
                Hoewel de oorsprong van dit probleem mogelijk bij de deelnemers zelf ligt, erkennen we volledig de ernst en
                de mogelijke gevolgen voor het pensioenfonds en het bredere vertrouwen in de sector. Als pensioenuitvoerder
                hebben we een verantwoordelijkheid naar al uw deelnemers en stakeholders.
            </p>
            <p>
                Ons Klant Contact Centrum is momenteel volop bezig met het behandelen van meldingen en het ondersteunen van
                betrokken deelnemers. We zetten alles op alles om de situatie te verhelderen, de gevolgen te beperken en,
                waar mogelijk, herstelwerkzaamheden te verrichten.
            </p>
            <p>
                Wij zullen u blijven informeren over de voortgang en ontwikkelingen in deze kwestie.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <hr />
            <p> Doorgestuurd bericht:
            </p>
            <p>Sjaak,</p>
            <p>
                Ik ben bezig met het onderzoek naar de oorzaak van de gegevens die online zouden staan. Hierbij kwam ik ook
                een opvallende email tegen in een spamfolder. Ik weet niet of deze gerelateerd is of niet, maar wellicht wel
                goed om het betreffende account te informeren. Hou er rekening mee dat dit ook gewoon nog een scam kan zijn,
                maar we kunnen nu niks uitsluiten.
            </p>
            <p>
                Pak jij dit verder op?
            </p>
            <p>
                Hieronder het bericht:
            </p>
            <hr />
            <p class="hack">
                Subject: Your Data Is in Our Control
            </p>
            <p class="hack">
                Dear sir, madam,
            </p>
            <p class="hack">
                This is a representative from the DataLock Group. We are reaching out to inform you that we have
                successfully penetrated your digital systems and copied and encrypted all your valuable data. If you doubt
                the veracity of this claim, you might have noticed the existence of the file “datalock.txt” that appeared in
                the shared application folder of your systems.
            </p>
            <p class="hack">
                If you wish for your data to remain confidential and seek its safe release, here are our terms:
            </p>
            <p class="hack">
            <ul>
                <li>You are to make a payment of 500 Bitcoin (BTC) to the following address:
                    1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa
                </li>
                <li>You have exactly 48 hours from the time this email was sent to complete the transaction.
                </li>
            </ul>
            </p>
            <p class="hack">If we confirm receipt of the payment within the stipulated time, we will provide you with the
                decryption key to regain access to your data and will ensure all copies in our possession are destroyed.
            </p>
            <p class="hack">
                Failure to comply with our terms will result in the partial release of your data to the public and on
                various platforms where its impact will be most damaging.
            </p>
            <p class="hack">
                Any attempt to seek external help, involve law enforcement, or fail to meet our demands within the given
                time frame will have severe consequences.
            </p>
            <p class="hack">
                This is not a situation you can ignore or delay. The clock is ticking.
            </p>
            <p class="hack">
                Regards,
            </p>
            <p class="hack">
                DataLock Group Representative
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-16'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-16-attachment-1.pdf')">Attachment 1 -
                    email.pdf</a>
            </p>
            <p>
                Beste leden van het pensioenbestuur,
            </p>
            <p>
                Met dit bericht wil ik u op de hoogte brengen van een recente ontwikkeling die betrekking kan hebben op de
                persoonlijke gegevens van uw deelnemers.
            </p>
            <p>
                Onlangs hebben wij vernomen dat er persoonlijke gegevens, die overeenkomen met die van sommige van uw
                deelnemers, gesignaleerd zijn op het darkweb. Ik wil benadrukken dat de aanwezigheid van deze gegevens op
                het darkweb niet direct betekent dat deze gegevens afkomstig zijn uit onze eigen systemen, noch dat er een
                bevestigd datalek binnen onze organisatie is.
            </p>
            <p>
                Echter, in het kader van onze zorgplicht vinden wij het essentieel om transparant te zijn en alle mogelijke
                voorzorgsmaatregelen te nemen. Daarom is het wellicht verstandig om passende maatregelen te nemen om
                mogelijke risico's voor de deelnemers te beperken of uit te sluiten.
            </p>
            <p>
                Ons team is momenteel druk bezig met het onderzoeken van deze situatie in samenwerking met
                cybersecurity-experts om de bron en omvang van deze gegevens op het darkweb te achterhalen. We zullen
                uiteraard alle noodzakelijke stappen ondernemen om de integriteit van onze systemen en de veiligheid van de
                gegevens van onze deelnemers te waarborgen.
            </p>
            <p>
                Als vanzelfsprekend is het geen enkel probleem dat wij deze communicatie voor onze rekening nemen. Het is
                immers in ons belang om alle deelnemers eenduidig te informeren.
            </p>
            <p>
                Ik blijf graag met u in nauw overleg over verdere ontwikkelingen en benader u spoedig met een update. Uw
                feedback en richtlijnen zijn in deze situatie van essentieel belang.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
            <hr />
            <p class="hack"> Doorgestuurd bericht:
            </p>
            <p class="hack">Sjaak,</p>
            <p class="hack">
                Ik ben bezig met het onderzoek naar de oorzaak van de gegevens die online zouden staan. Hierbij kwam ik ook
                een opvallende email tegen in een spamfolder. Ik weet niet of deze gerelateerd is of niet, maar wellicht wel
                goed om het betreffende account te informeren. Hou er rekening mee dat dit ook gewoon nog een scam kan zijn,
                maar we kunnen nu niks uitsluiten.
            </p>
            <p class="hack">
                Pak jij dit verder op?
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-17'">
            <p>
                PERSBERICHT
            </p>
            <h1>
                Potentieel datalek bij Pensioenfonds Vertrouwen
            </h1>
            <p>
                Pensioenfonds Vertrouwen maakt bekend dat er een potentieel datalek heeft plaatsgevonden. Uit voorlopige
                bevindingen blijkt dat de oorzaak van dit incident te herleiden is naar een van de externe partners van het
                fonds, belast met administratieve verwerkingen.
            </p>
            <p>
                Het bestuur van Pensioenfonds Vertrouwen neemt dit incident uiterst serieus en heeft onmiddellijk
                maatregelen getroffen. Welke gegevens en in welke omvang het datalek omvat is nog onbekend. Dat er gegevens
                gelekt zijn, is geen onderwerp van discussie. Een grondig onderzoek is geïnitieerd om de exacte omvang, aard
                en betrokken gegevens te specificeren.
            </p>
            <p>
                In een verklaring zegt het bestuur: "De veiligheid en privacy van de gegevens van onze deelnemers vormen de
                kern van ons vertrouwen. We zijn diep getroffen door dit incident en zetten ons maximaal in om de situatie
                recht te zetten en verdere risico's te minimaliseren. Wij kiezen er niet voor om af te wachten tot alle
                onderzoeksresultaten bekend zijn, maar kiezen ervoor om direct onze deelnemers te waarschuwen."
            </p>
            <p>
                Alle deelnemers van het pensioenfonds zijn of worden geïnformeerd over de stand van zaken. Aanvullende
                details zullen verstrekt worden zodra deze beschikbaar zijn. Het pensioenfonds benadrukt dat dit niet
                betekent dat alle deelnemers getroffen zijn, of dat er per definitie gegevens van gevoelige aard publiek
                zijn geworden. Zodra bekend is welke deelnemers daadwerkelijk getroffen zijn door het datalek, zullen deze
                dan afzonderlijk via brief worden geïnformeerd. Het kan echter enige weken duren voordat deze gegevens
                bekend zijn.
            </p>
            <p>
                Voor continue informatieverstrekking wordt op korte termijn een specifieke informatie website opgezet, die
                gedetailleerde en actuele informatie over de situatie aan de deelnemers verschaft.
                Het fonds benadrukt dat het klantcontactcentrum geen andere vragen kan beantwoorden en informatie kan geven
                dan op de website staat.
            </p>
            <p>
                Het fonds roept de deelnemers op om waakzaam te zijn voor phishingpogingen. Zo vragen medewerkers nooit om
                inloggegevens of wachtwoorden, of om bepaalde software te installeren.
            </p>
            <p>
                Conform wettelijke verplichtingen is het incident gemeld bij de Autoriteit Persoonsgegevens.
            </p>
            <p>
                Voor verdere vragen verzoeken wij u contact op te nemen met de afdeling Communicatie van Pensioenfonds
                Vertrouwen.
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            <ol class="action-options">
                <li>Doe niks.</li>
                <li>Zoek contact met andere geraakte fondsen.</li>
                <li>Vraag de PUO om verduidelijking omtrent welke systemen en gegevens gehackt zijn.</li>
                <li>Geef de nieuwe informatie door aan de toezichthouder</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Doe niks" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Zoek contact" @click="answerEmail(1)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="3. Vraag PUO" @click="answerEmail(2)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="4. Toezichthouder" @click="answerEmail(3)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-16b'">
            <img src="@/assets/email-17b.png">
        </div>

        <div class="email-body" v-if="email.id == 'stap-18'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                In mijn vorige bericht heb ik u geïnformeerd over de situatie, waarbij het incident beperkt is gebleven tot
                een geïsoleerd systeem. Ook heb ik aangegeven dat onderwerp van het onderzoek alleen gegevens zijn van
                deelnemers van uw pensioenfonds. Ik hecht er waarde aan om u volledig en pro-actief te informeren, ook als
                dit betekent dat ik een eerder bericht moet verduidelijken om eventuele misverstanden op een later moment te
                voorkomen.
            </p>
            <p>
                In het kader van de volledigheid wil ik aangeven dat de scope van het onderzoek dat uitgevoerd wordt de
                gehele organisatie betreft. Ook worden de systemen onderzocht welke niet door uw pensioenfonds worden
                gebruikt. Mochten wij hier anomalieën tegenkomen, dan is mij intern verzekerd dat hier adequate en gepaste
                mitigerende maatregelen genomen zijn.
            </p>
            <p>
                U zult begrijpen dat de uitkomst van het onderzoek naar de systemen welke in gebruik zijn bij andere
                pensioenfondsen niet met u gedeeld kan worden vanwege de verschillende contracten en wetgeving. Mijn eerdere
                email over het geïsoleerde systeem moet u dan ook lezen dat ik u alleen kan rapporteren over de bevindingen
                met betrekking tot de veiligheid van de systemen die door uw pensioenfonds in gebruik zijn.
            </p>
            <p>
                We waarderen uw geduld en vertrouwen in deze kwestie en zullen u blijven informeren over verdere
                ontwikkelingen.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
        </div>

        <div class="email-body" v-if="email.id == 'stap-19'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-19-attachment-1.pdf')">Attachment 1 -
                    scan.pdf</a>
            </p>
            <p>
                Hi allen,
            </p>
            <p>
                Deze is urgent, kwam net binnen.<br />
                Zie attachment, de Autoriteit Persoonsgegevens wil dat jullie meteen terugbellen!
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Reageer niet" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate"
                text="2. Klik hier als je de toezichthouder hebt gesproken" @click="emailStap19(1)" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-20'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-20-attachment-1.pdf')">Attachment 1 -
                    artikel.pdf</a>
            </p>
            <p>
                Hoi,
            </p>
            <p>
                Ik kreeg net een linkje doorgestuurd, blijkbaar worden we genoemd op WeinigStijl. Oei.
                Ik heb 'm even opgeslagen (zie attachment), het klinkt niet erg positief allemaal.
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-21'">
            <p>
                Geacht pensioenfondsbestuur,
            </p>
            <p>
                De afgelopen twee weken zijn we geconfronteerd met meldingen van phishing en wijzigingen in de gegevens van
                onze deelnemers. Vanuit onze commitment aan veiligheid en integriteit zijn we direct proactief aan de slag
                gegaan om de bron van deze wijzigingen te identificeren en om maatregelen te nemen ter bescherming van de
                integriteit van onze betaalruns.
            </p>
            <p>
                We hebben de mogelijkheid onderzocht om, indien nodig, bepaalde gegevens van voor het begin van deze
                incidenten te gebruiken. Ons backupsysteem werkt met een geavanceerd point-in-time recoverysysteem. Dit
                houdt in dat we tot op 5 minuten nauwkeurig een backup van de afgelopen twee weken kunnen terugzetten.
                Gezien de phishingmeldingen van de afgelopen twee weken is het realistisch te veronderstellen dat, indien er
                besloten wordt op gegevens uit een eerdere run te gebruiken, we besluiten om een backup van voor dit
                tijdsbestek van twee weken te gebruiken.
            </p>
            <p>
                Voor wat betreft de opslag van deze backups: uit veiligheidsoverwegingen bewaren we de backups, afhankelijk
                van hun leeftijd, op verschillende locaties. We kunnen bevestigen dat de backups van drie maanden en ouder
                succesvol zijn gelokaliseerd en gevalideerd. Echter, voor de backups die jonger zijn dan drie maanden zijn
                we enkele technische uitdagingen tegengekomen. Ons team van technici is met hoge prioriteit bezig om deze
                uitdagingen aan te pakken en te onderzoeken hoe deze backups zo snel mogelijk weer volledig bruikbaar zijn.
            </p>
            <p>
                Ik wil benadrukken dat we op dit moment geen enkele aanwijzing hebben dat de gegevens uit onze backups op
                enige wijze zijn misbruikt of gelekt. De veiligheid van de gegevens van onze deelnemers blijft onze
                topprioriteit.
            </p>
            <p>
                We waarderen uw geduld en vertrouwen in deze kwestie en zullen u op de hoogte houden van verdere
                ontwikkelingen.
            </p>
            <p>
                Met vriendelijke groet,</p>
            <p>
                Sjaak de Vries
            </p>
            <SjaakSignature />
        </div>

        <div class="email-body" v-if="email.id == 'stap-21b'">
            <p>
                PERSBERICHT
            </p>
            <h1>
                “Beveiligingsonderzoekers kraken 'ShadowCage' ransomware van 'Binary Brotherhood'”
            </h1>
            <p>
                In een baanbrekende ontwikkeling hebben beveiligingsexperts van het gerenommeerde cyberveiligheidsbedrijf
                CyberGuardian een doorbraak bereikt in de strijd tegen cybercriminaliteit. Ze hebben succesvol de
                kwaadaardige ransomware 'ShadowCage' ontsleuteld, die verantwoordelijk is voor talrijke aanvallen op
                organisaties wereldwijd en wordt geassocieerd met de beruchte hackersgroep 'Binary Brotherhood'.
            </p>
            <p>
                De 'ShadowCage' ransomware werd voor het eerst geïdentificeerd in een reeks van gecoördineerde aanvallen
                eerder dit jaar en stond erom bekend bedrijfsdata te gijzelen met een complexe encryptie. Slachtoffers
                werden geconfronteerd met exorbitante losgeldeisen, vaak in de vorm van cryptocurrencies, om weer toegang te
                krijgen tot hun essentiële gegevens.
            </p>
            <p>
                Door nauwgezette analyse en reverse-engineering zijn de onderzoekers van CyberGuardian er niet alleen in
                geslaagd de encryptie-algoritmen van de ransomware te doorgronden, maar hebben ze ook een gratis unlocktool
                ontwikkeld. Deze tool, die nu beschikbaar is voor het publiek, stelt getroffen organisaties in staat om hun
                versleutelde bestanden te herstellen zonder te buigen voor de eisen van de cybercriminelen.
            </p>
            <p>
                CyberGuardian heeft de unlocktool gepubliceerd op zijn officiële website en roept organisaties op om
                waakzaam te blijven en hun cyberveiligheidsmaatregelen te versterken. De vrijgegeven tool is een zeldzame
                overwinning tegen cybercriminaliteit en een hoopgevend teken dat samenwerking en expertise de sleutels zijn
                tot het bestrijden van deze dreigingen.
            </p>
            <p>
                Deze ontwikkeling is een forse tegenslag voor 'Binary Brotherhood', die eerder als ongrijpbaar en zeer
                geavanceerd werd beschouwd. Met de ontsleutelingscodes nu in handen van de goede jongens, is er een golf van
                opluchting door de cybersecuritygemeenschap gegaan. De hoop is dat dit succes een afschrikmiddel zal zijn
                voor andere cybercriminelen die overwegen om ransomware als een hulpmiddel voor hun misdadige winsten te
                gebruiken.
            </p>
            <p>
                Het nieuws wordt gezien als een overwinning voor cyberveiligheid en een reminder van de kracht van
                veerkracht en innovatie in de digitale wereld.
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-22'">
            <p>
                <span class="span-attachments">Attachments:</span>
            </p>
            <p class="attachments">
                <a class="btn-attachment"
                    @click.prevent="openOverlayWithUrl('/attachments/stap-22-attachment-1.pdf')">Attachment 1 -
                    rapport.pdf</a>
            </p>
            <p>
                Hoi allen,
            </p>
            <p>
                Het technisch rapport van de PUO is binnen, zie attachment.
            </p>
            <p>
                Groet,
            </p>
            <p>
                Ferdy Boulanger,<br />
                bestuurssecretaris
            </p>
        </div>

        <div class="email-body" v-if="email.id == 'stap-23'">
            <img src="@/assets/stap-23-attachment-1.png" class="image-attachment" />
        </div>

        <div class="email-body" v-if="email.id == 'stap-23b'">
            <p>
                Geachte heer, mevrouw,
            </p>
            <p>
                Tot mijn spijt moet ik u informeren over een beveiligingsprobleem binnen uw organisatie of bij een van uw
                toeleveranciers. Een aanzienlijk en serieus probleem. Als resultaat van dit beveiligingsprobleem zijn de
                persoonsgegevens van 46.712 personen in ons bezit gekomen. Het betreft hier onder andere namen,
                e-mailadressen, burgerservicenummers (BSN) en IBAN’s en uitkeringsbedragen.
            </p>
            <p>
                Het beveiligingsprobleem is geen klein incident, maar structureel van aard. Ter illustratie hebben we daarom
                ook de bestanden op een fileserver met de naam BA-SF-01 versleuteld. Ook zijn we in staat geweest om enkele
                gegevens in de dataset te veranderen, om zo onze werkzaamheden te financieren.
                Kortom: dit is een belangrijke veiligheidskwestie die direct uw aandacht nodig heeft.
            </p>
            <p>
                Als groepering hechten wij veel waarde aan een veilig internet en wij benadrukken bij organisaties
                wereldwijd de ernst hiervan. Dit doen we op een wijze die niet genegeerd en in de doofpot gestopt kan
                worden. Wij vinden het essentieel dat alle betrokken gebruikers worden geïnformeerd en dat de organisatie
                leert van deze gebeurtenis.
            </p>
            <p>
                U zult begrijpen dat onze groepering financiering nodig heeft. In ruil voor een betaling van 500 bitcoins
                zullen wij alle verkregen data vernietigen en de sleutels verschaffen om de bestanden op de fileserver te
                ontsleutelen. U kunt deze betaling zien als een investering in het veiligheidsbeleid van uw organisatie. U
                krijgt immers een realistische training en educatie voor uw organisatie, de betreffende afdelingen en
                medewerkers.
            </p>
            <p>
                Indien u kiest niet te betalen, dan zullen we genoodzaakt zijn de gegevens openbaar te maken. Het is dan een
                duidelijk signaal naar de wereld dat uw organisatie beveiliging en privacy niet serieus neemt. Tevens zullen
                wij dan onze financiering op alternatieve manieren moeten veiligstellen.
            </p>
            <p>
                Beveiliging en privacy mogen niet genegeerd of uitgesteld worden en u moet nu een keus maken. U heeft 10
                minuten om uw beslissing te nemen.
            </p>
            <p>
                Ik verwacht dat u de juiste beslissing neemt. U kunt de betaling van 500 bitcoins sturen naar het adres
                1FicTieF123aBCdeFgHiJKlmnOPqRsTuvWXYz. Mocht u problemen ervaren, dan kunt u binnen de 5 minuten contact
                opnemen, dan assisteren wij u graag verder.
            </p>
            <p>
                Met vriendelijke groet,
            </p>
            <p>
                Binary Brotherhood
            </p>
            <p>
                <span class="span-important">Actie ondernemen:</span>
                <TimeoutView v-if="email.duration && email.duration > 0 && !email.isDone" :durationSeconds="email.duration"
                    :remainingSeconds="email.remaining" />
                <!-- <ol class="action-options">
                <li>Negeer de hackers. Het risico is wel dat het geduld van de hackers opraakt en ze alsnog bestanden
                    vrijgeven of helemaal verwijderen.</li>
                <li>Betaal de hackers. Het risisco is wel dat ze alsnog de verkeerde sleutel geven, of data gewoon gewist
                    is. Ook is de integriteit van de data onzeker.</li>
                <li>Probeer tijd te rekken zodat ondertussen additioneel forensisch onderzoek plaats kan vinden. Het risico
                    is wel dat het geduld van de hackers opraakt en ze alsnog bestanden vrijgeven of helemaal verwijderen.
                </li>
                <li>Betaal de hackers niet. Het risico is dat ze de bestanden vrijgeven of helemaal verwijderen.</li>
            </ol>
            </p>
            <ButtonView
                :done="email.isDone" :tooLate="email.isTooLate" 
                text="1. Negeer" @click="answerEmail(0)" />
            <ButtonView
                :done="email.isDone" :tooLate="email.isTooLate" 
                text="2. Betaal" @click="answerEmail(1)" />
            <ButtonView
                :done="email.isDone" :tooLate="email.isTooLate" 
                text="3. Rek tijd" @click="answerEmail(2)" />
            <ButtonView
                :done="email.isDone" :tooLate="email.isTooLate" 
                text="4. Betaal niet" @click="answerEmail(3)" />-->
            <ol class="action-options">
                <li>Betaal de hackers niet. Het risico is dat ze de bestanden vrijgeven of helemaal verwijderen.</li>
                <li>Betaal de hackers. Het risisco is wel dat ze alsnog de verkeerde sleutel geven, of data gewoon gewist
                    is. Ook is de integriteit van de data onzeker.</li>
            </ol>
            </p>
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="1. Betaal niet" @click="answerEmail(0)" />
            <ButtonView :done="email.isDone" :tooLate="email.isTooLate" text="2. Betaal" @click="answerEmail(1)" />
        </div>

        <div class="email-done" v-if="email.important && email.isDone && !email.isTooLate"></div>
        <div class="email-too-late" v-if="email.important && email.isTooLate"></div>
    </div>

    <div class="control-panel-content email-content container-fluid" style="margin-top: 1rem;" v-if="$route.params.reply">
        <router-view></router-view>
    </div>
</template>

<script>
import api from '@/repository/api.js'
import ButtonView from '@/components/controlpanel/ButtonView.vue';
import gametree from '@/repository/gametree';
import { game } from '@/repository/gamestate';
import OverlayBrowser from '@/components/OverlayBrowser.vue';
import SjaakSignature from './SjaakSignature.vue';
import TimeoutView from '@/components/controlpanel/TimeoutView.vue';

//const debugTestTimers = true;

export default {
    name: "ControlPanelInboxEmail",
    components: {
        ButtonView, OverlayBrowser, SjaakSignature, TimeoutView
    },
    data() {
        return {
            radioSource: require("@/assets/sounds/radiouitzending.mp3"),
            email: {
                avatar: "ic_avatar.svg",
            },
            iframeURL: '',
            openOverlay: false,
            gameInterval: null,
        }
    },
    beforeRouteUpdate(to, from, next) {
        const messageId = to.params.messageId;
        this.refresh(messageId);
        next();
    },
    mounted() {
        const messageId = this.$route.params.messageId;
        this.refresh(messageId);
    },
    unmounted() {
        console.log('stopping the clock');
        this.stopClock();
    },
    computed: {
        avatarSrc() {
            return require('@/assets/' + (this.email.avatar ? this.email.avatar : 'ic_avatar.svg'));
        }
    },
    methods: {
        startClock() {
            if (this.gameInterval) {
                this.stopClock();
            }
            this.gameInterval = setInterval(this.tick, 1000);
        },
        stopClock() {
            if (this.gameInterval) {
                clearInterval(this.gameInterval);
            }
        },
        tick() {
            if (this.email.timerEnd > 0) {
                this.email.remaining = Math.floor((this.email.timerEnd - Date.now()) / 1000);
                if (this.email.remaining <= 0) {
                    this.stopClock();
                    this.email.isTooLate = true;
                }
            }
            // this.email.remaining = Math.max(0, this.email.remaining - 1);
            // if (this.email.remaining <= 0) {
            //     this.stopClock();
            //     this.email.isTooLate = true;
            // }
        },
        refresh(messageId) {
            //console.log(`Reading email with id: ${messageId}`);
            // Find the email by ID in the gamestate
            const e = game.inbox.find(email => email.id === messageId);
            const dilemma = this.$store.state.session.steps.find((s) => {
                return e.dilemmaId == s.dilemmaId;
            });
            if (!dilemma) {
                console.error(`no dilemma for email: ${e.dilemmaId}`);

            }
            e.isDone = dilemma.isDone;
            e.isRead = dilemma.isRead;
            e.isTooLate = dilemma.isTooLate;
            // Check if the email has a timeout
            if (e.important && !dilemma.isDone) {
                if (dilemma.duration && dilemma.duration > 0) {
                    console.log(`This dilemma has a timeout of ${dilemma.duration / 1000} seconds`);

                    // Calculate remaining time
                    var startTime = new Date(this.$store.state.session.startTime).getTime();
                    const startTimeRound2 = this.$store.state.session.startTimeRound2;
                    if (startTimeRound2) {
                        startTime = new Date(startTimeRound2).getTime();
                        console.log(`ROUND 2 started at: ${startTimeRound2}`);
                    } else {
                        console.log(`Round 1 started at: ${this.$store.state.session.startTime}`);
                    }

                    if (startTime > 0 && dilemma.duration) {
                        const now = Date.now();
                        //var timeElapsed = now - startTime;
                        // Check if the dilemma has started
                        //if (timeElapsed < dilemma.startAt) {
                        if (now < new Date(dilemma.startTime).getTime()) {
                            //console.log(`    Time elapsed since start: ${timeElapsed / 1000}sec, which is BEFORE dilemma's start (${dilemma.startAt / 1000}sec). Apparently the game leaders skipped ahead. Recalculating...`);
                            console.log(`    Time: ${now}, which is BEFORE dilemma's start (${dilemma.startTime}). Apparently the game leaders skipped ahead. THIS SHOULD NO LONGER HAPPEN!`);
                            // // We don't know for how long, since we don't record the start time of each dilemma, BUT we can correct for the rest of the game
                            // // by modifying the startTime locally by the same amount.
                            // const difference = dilemma.startAt - timeElapsed;
                            // console.log(`    Changing start time by ${difference / 1000}sec from ${new Date(startTime).toISOString()} to ${new Date(startTime + difference).toISOString()}`);
                            // startTime = startTime + difference;
                            // timeElapsed = dilemma.startAt;
                            // // Update in the locale storage
                            // let newStartTime = new Date(startTime).toISOString();
                            // // if (isRound2) {
                            // //     this.$store.dispatch('setStartTimeRound2', newStartTime);
                            // // } else {
                            // //     this.$store.dispatch('setStartTime', newStartTime);
                            // // }
                            // this.$store.dispatch('setStartTime', newStartTime);
                        }

                        console.log(`    Time: ${now}, which is AFTER dilemma's start (${dilemma.startTime})`);
                        const remainingMillis = (new Date(dilemma.startTime).getTime() + dilemma.duration) - now;
                        if (remainingMillis > 0) {
                            console.log(`        Duration is ${dilemma.duration / 1000}sec, so there are ${remainingMillis / 1000}sec left`);
                            e.remaining = Math.floor(remainingMillis / 1000);
                            e.timerEnd = new Date(dilemma.startTime).getTime() + dilemma.duration;
                            this.startClock();
                        } else {
                            console.log(`        Duration is ${dilemma.duration / 1000}sec, so there is no time left (${remainingMillis / 1000}sec)`);
                            // We're done
                            e.remaining = 0;
                            e.timerEnd = 0;
                            //e.isDone = true;
                            e.isTooLate = true;
                        }
                        e.duration = Math.round(dilemma.duration / 1000);
                    }
                    // } else {
                    // console.log(`This email has no timeout (${dilemma.duration})`);
                }
            } else {
                //console.log('This email is not actionable or already done');
                e.duration = -1;
            }
            this.email = e;
            // First time we open the email, we mark it as read
            // (both in the state as in the api)
            if (!dilemma.isRead) {
                const token = this.$store.state.sessionToken;
                api.emailOpened(token, this.email.dilemmaId);
                this.$store.dispatch('markAsRead', this.email.dilemmaId);
            }
        },

        scrollToTop() {
            //document.querySelector('#inbox-top').scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo(0, 0);
        },

        bekijkMeters() {
            this.$router.push({ name: 'ControlPanelMeters' });
        },

        answerEmail(answerIndex) {
            if (this.email.isDone || this.email.isTooLate) return;

            const token = this.$store.state.sessionToken;
            const messageId = this.$route.params.messageId;
            const variant = this.$store.state.session.variant;
            const progress = gametree.findDilemmaProgress(variant, this.email.dilemmaId);
            const progressState = gametree.progressStates(variant)[progress];
            const answer = progressState.answers[answerIndex];

            // Log
            api.emailActioned(token, messageId, answer.title);
            // Store answer in backend
            this.$store.dispatch('answerDilemma', { dilemmaId: this.email.dilemmaId, progress: progress, answer: answerIndex });
            //this.$store.dispatch('markAsDone', this.email.dilemmaId);
            this.email.isDone = true;

            this.stopClock();
            this.scrollToTop();
        },

        emailStap2(answerIndex) {
            if (this.email.isDone) return;

            this.answerEmail(answerIndex);

            if (answerIndex == 2) {
                // Waarschuwing plaatsen op website
                const messageId = this.$route.params.messageId;
                this.$store.dispatch('showNews', messageId);
            }
        },
        emailStap4(answerIndex) {
            if (this.email.isDone) return;
            this.answerEmail(answerIndex);

            // 'Close the portal'
            if (answerIndex == 2) {
                this.$store.dispatch('enablePortal', false);
            }
        },
        emailStap19(answerIndex) {
            if (this.email.isDone) return;
            // TODO ideally we'd not close it here, but let the admin close it after the call, through the panel.
            this.answerEmail(answerIndex);
            // Show phone number
            // this.$store.dispatch('revealPhoneNumbers', true);
            // this.gotoVoicemails();
        },

        // gotoVoicemails() {
        //     this.$router.push({ name: 'ControlPanelPhone' });
        // },

        openOverlayWithUrl(url) {
            this.iframeURL = url;
            this.openOverlay = true;
        },
        markAsRead() {
            const dilemmaId = "stap-7b";
            const token = this.$store.state.sessionToken;
            api.emailOpened(token, dilemmaId);
            this.$store.dispatch('markAsRead', dilemmaId);
        }
    }
}
</script>

<style scoped>
.small-caption {
    font-size: 14pt;
    display: block;
    opacity: 81%;
}

.email-subject,
.email-from {
    display: block;
    color: #000;
    font-family: Roboto;
    font-size: 1.48756rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.email-subject {
    color: #000;
    font-family: Roboto;
    font-size: 1.8505rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.email-from,
.email-to,
.avatar {
    float: left;
}

.email-from {
    clear: right;
    margin-bottom: 2rem;
}

.email-body {
    clear: both;
}

.email-body .action-options li {
    font-weight: bold;
}

.email-done {
    background-image: url("@/assets/stamp_done.png");
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    left: 3em;
    opacity: 0.5;
    width: 100%;
    height: 12em;
    display: block;
    position: absolute;
    bottom: -1em;
}

.email-too-late {
    background-image: url("@/assets/stamp_too_late.png");
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    left: 3em;
    opacity: 0.5;
    width: 100%;
    height: 12em;
    display: block;
    position: absolute;
    bottom: -1em;
}

.avatar {
    margin-right: 0.8rem;
    border-radius: 50%;
}

.email-address,
.email-to {
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto;
    font-size: 1.33563rem;
    font-style: normal;
    font-weight: 500;
    line-height: 166%;
    letter-spacing: 0.01rem;
}

.email-address::before {
    content: '<';
}

.email-address::after {
    content: '>';
}

.admin-button {
    width: 100%;
    text-align: left;
    padding-left: 2rem;
}

.span-attachments,
.span-important {
    color: gray;
    font-size: 1.2rem;
    margin: 3.5rem 0.5rem 0.5em -0.3em;
}

.btn-attachment {
    width: 100%;
    margin: 0.5rem 1rem;
    color: gray;
    text-decoration: underline !important;
    font-family: monospace;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
}

p.attachments {
    background: #f0f4ff;
    border-radius: 1rem;
    margin-bottom: 3rem;
}

p.hack {
    font-style: italic;
}

.voicemail-audio {
    width: 30%;
}

audio {
    background: white;
    border: 2px solid var(--color-blue);
    border-radius: 27px;
    width: 100%;
    /* transform: scale(1.25) translate(-7%, -8px); */
}

video {
    background-color: #ffffff;
    width: 100%;
}

.image-attachment {
    width: 100%;
}

table {
    border-collapse: unset;
    border-spacing: 3px;
}

.table th {
    border-top: 0;
    border-bottom: 0;
}

.control-panel-voicemail {
    padding: 20px;
}

.voicemails-list {
    list-style-type: none;
    padding: 0;
}

.voicemails-list li {
    margin-bottom: 20px;
}

tr.voicemail-read {
    border-radius: 1rem;
}

td a {
    color: black;
}

.voicemail-read td,
.voicemail-unread td {
    font-size: 1.6rem;
}

.voicemail-read {
    background-color: transparent;
}

.voicemail-unread {
    font-weight: 700;
}

.voicemail-unread td {
    background-color: #DCE6FF;
}

.voicemail-unread td:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

td:first-child {
    padding-left: 1.5rem;
    padding-right: 0;
}

.voicemail-unread td:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

tr,
td {
    border: 0;
    border-top: 0;
    /* Resetting any previous borders */
}

.voicemail-name {
    width: 30%;
}

.voicemail-audio {
    width: 30%;
}

audio {
    background: white;
    border: 2px solid var(--color-blue);
    border-radius: 27px;
    width: 100%;
    /* transform: scale(1.25) translate(-7%, -8px); */
}
</style>
